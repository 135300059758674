@use '@angular/material' as mat;
@include mat.core();

// ===============================
// Variables
// ===============================
:root {
  --max-width: 640px;
  --min-width: 375px;
  // 預設的變數色碼
  --tab-bg-color: linear-gradient(0deg, #9e9e9e 0%, #666666 100%);
  --main-color: #f36633;
  --component-color: #f36633;
  --component-disabled-color: #33333333;
  --text-color: #333333;
  --bg-color: #ecedef;
  --icon-secondary-color: #333333;
  // Figma 色碼規格（灰色）
  --gray-1_F5F5F5: #f5f5f5;
  --gray-2_DADCE0: #dadce0;
  --gray-3_9E9E9E: #9e9e9e;
  --gray-4_858585: #858585;
  --gray-5_666666: #666666;
  --gray-6_333333: #333333;

  // Figma 色碼規格（輔助用色）
  --Color-Blue: #0094ff;
  --Color-Red: #f55b41;
  --Color-Light-Red: #f8d7da;
  --Black: #000000;
  --White: #ffffff;
  --Green: #00b900;

  // checkbox 顏色
  --mdc-checkbox-selected-checkmark-color: var(--White); // 勾勾顏色
  --mdc-checkbox-unselected-icon-color: var(--gray-2_DADCE0); // 框線顏色

  // 按鈕 顏色
  --mdc-filled-button-label-text-color: var(--White); // filled button 文字顏色
  --mdc-outlined-button-label-text-color: var(--component-color); // outlined button 文字顏色
  --mdc-outlined-button-outline-color: var(--component-color); // outlined button 邊框顏色
  --mdc-filled-button-container-color: var(--component-color); // filled button 背景色
  --mdc-text-button-label-text-color: var(--component-color); // text button 文字顏色
  // disabled 按鈕 顏色
  --mdc-filled-button-disabled-label-text-color: var(--White); // filled button disabled 文字顏色
  --mdc-outlined-button-disabled-outline-color: var(--component-disabled-color); // outlined button disabled 邊框顏色
  --mdc-filled-button-disabled-container-color: var(--component-disabled-color); // filled button disabled 背景色

  // 按鈕
  --button-container-shape: 24px; // button 圓角
  --button-container-height: 48px; // button 高度
  --button-label-text-size: 14px; // button 文字大小

  --mat-icon-color: var(--main-color); // mat-icon 顏色

  --mdc-filled-button-container-shape: var(--button-container-shape); // filled button 圓角
  --mdc-outlined-button-container-shape: var(--button-container-shape); // outlined button 圓角
  --mdc-filled-button-container-height: var(--button-container-height); // filled button 高度
  --mdc-outlined-button-container-height: var(--button-container-height); // outlined button 高度
  --mdc-filled-button-label-text-size: var(--button-label-text-size); // filled button 文字大小
  --mdc-outlined-button-label-text-size: var(--button-label-text-size); // outlined button 文字大小

  // form field
  --mat-form-field-container-vertical-padding: 12px; // form field 垂直 padding
  --mat-form-field-container-height: 48px;
  --mdc-outlined-text-field-outline-color: var(--bg-color); // outlined text field 預設 邊框顏色

  // selector
  --mdc-outlined-text-field-label-text-size: 14px; // outlined text field label 文字大小
}

// ===============================
// Material Styles
// ===============================
// 客制調色盤
$aiii-liff-med-palette: mat.define-palette(
  (
    // 元件主色
    main: var(--component-color),
    50: var(--gray-1_F5F5F5),
    100: var(--gray-2_DADCE0),
    200: var(--gray-3_9E9E9E),
    300: var(--gray-4_858585),
    400: var(--gray-5_666666),
    500: var(--gray-6_333333),
    600: var(--gray-6_333333),
    700: var(--gray-6_333333),
    800: var(--gray-6_333333),
    900: var(--gray-6_333333),
    A100: var(--Color-Blue),
    A200: var(--Color-Blue),
    A400: var(--Color-Blue),
    A700: var(--Color-Red),
    contrast: (
      50: var(--gray-6_333333),
      100: var(--gray-6_333333),
      200: var(--gray-6_333333),
      300: var(--gray-6_333333),
      400: var(--gray-6_333333),
      500: white,
      600: white,
      700: white,
      800: white,
      900: white,
      A100: var(--gray-6_333333),
      A200: var(white),
      A400: var(--gray-6_333333),
      A700: var(--gray-6_333333),
    )
  )
);

$aiii-liff-med-primary: mat.define-palette($aiii-liff-med-palette, main); // 主色 component-color：button、icon
$aiii-liff-med-accent: mat.define-palette($aiii-liff-med-palette, main); // 輔助色 component-color：chebox、radio
$aiii-liff-med-warn: mat.define-palette($aiii-liff-med-palette, A700); // 警示色 Color-Red

// 主題
$aiii-liff-med-theme: mat.define-light-theme(
  (
    color: (
      primary: $aiii-liff-med-primary,
      accent: $aiii-liff-med-accent,
      warn: $aiii-liff-med-warn,
    ),
  )
);
@include mat.all-component-themes($aiii-liff-med-theme);

// 設置 input 的背景顏色
.mat-mdc-text-field-wrapper {
  background-color: #ffffff;
  border-radius: 4px !important;
}

// 設置 input 的錯誤訊息文字大小
.mat-mdc-form-field-error {
  font-size: 12px;
}

.mdc-button {
  width: 100%;
}

.mdc-checkbox__background {
  border-width: 1px !important;
}

.mat-icon {
  color: var(--component-color);
  font-family: 'Material Icons Round';
}

.aiiiIcon svg * {
  stroke: var(--text-color);
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-track-color: var(--gray-1_F5F5F5);
}

.mat-mdc-text-field-wrapper {
  height: 48px !important;
}

.mat-mdc-tooltip {
  font-size: 14px;
  max-width: none;
  width: auto;
}

// ===============================
// Reset Styles
// ===============================
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  font-family: 'Noto Sans TC', sans-serif;
}

ul,
ol {
  list-style: none;
}

button {
  cursor: pointer;
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  &:disabled {
    cursor: not-allowed;
  }
}

img,
iframe,
video {
  max-width: 100%;
  height: auto;
  border: none;
}

// 測試
input,
textarea {
  // font-size: clamp(16px, 1.5vw, 24px); /* 最小 16px，根據視窗大小調整，最大 24px */
  font-size: 16px;
}

.text-algin-center {
  text-align: center;
}

// ===============================
// Utility Classes
// ===============================
// 定義 margin-bottom 的單位
$mb-unit: 4px;

// 定義一個混合器來生成 margin-bottom 的樣式
@mixin mb($num) {
  margin-bottom: $num * $mb-unit;
}

@mixin mr($num) {
  margin-right: $num * $mb-unit;
}

// 生成 mb-1 到 mb-n 的類樣式
@for $i from 1 through 10 {
  .mb-#{$i} {
    @include mb($i);
  }
  .mr-#{$i} {
    @include mr($i);
  }
}

// 定義 dialog 底層 css
.cdk-overlay-container {
  .cdk-global-overlay-wrapper {
    .chat-template-dialog {
      mat-dialog-container {
        border-radius: 0px;
      }
    }
  }
}

// 客制scrollbar
/* 針對整體滾動條樣式 */
::-webkit-scrollbar {
  width: 4px; /* 滾動條的寬度 */
  height: 4px; /* 滾動條的高度（水平滾動條） */
}

/* 滾動條軌道的樣式 */
::-webkit-scrollbar-track {
  background: #f0f0f0; /* 軌道淺灰色 */
}

/* 滾動條滑塊的樣式 */
::-webkit-scrollbar-thumb {
  background: #c0c0c0; /* 滑塊稍深的灰色 */
  border-radius: 2px; /* 圓角效果 */
}

/* 滾動條滑塊在 hover 狀態的樣式 */
::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0; /* 鼠標懸停時稍深 */
}
